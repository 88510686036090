@font-face {
    font-family: 'Gotham Rounded';
    src: url('GothamRounded-Bold.eot');
    src: url('GothamRounded-Bold.eot?#iefix') format('embedded-opentype'),
        url('GothamRounded-Bold.woff2') format('woff2'),
        url('GothamRounded-Bold.woff') format('woff'),
        url('GothamRounded-Bold.ttf') format('truetype'),
        url('GothamRounded-Bold.svg#GothamRounded-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded Book';
    src: url('GothamRounded-Book.eot');
    src: url('GothamRounded-Book.eot?#iefix') format('embedded-opentype'),
        url('GothamRounded-Book.woff2') format('woff2'),
        url('GothamRounded-Book.woff') format('woff'),
        url('GothamRounded-Book.ttf') format('truetype'),
        url('GothamRounded-Book.svg#GothamRounded-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('GothamRounded-Medium.eot');
    src: url('GothamRounded-Medium.eot?#iefix') format('embedded-opentype'),
        url('GothamRounded-Medium.woff2') format('woff2'),
        url('GothamRounded-Medium.woff') format('woff'),
        url('GothamRounded-Medium.ttf') format('truetype'),
        url('GothamRounded-Medium.svg#GothamRounded-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('GothamRounded-Light.eot');
    src: url('GothamRounded-Light.eot?#iefix') format('embedded-opentype'),
        url('GothamRounded-Light.woff2') format('woff2'),
        url('GothamRounded-Light.woff') format('woff'),
        url('GothamRounded-Light.ttf') format('truetype'),
        url('GothamRounded-Light.svg#GothamRounded-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

