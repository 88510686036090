@import "../assets/fonts/stylesheet.css";

:root {
  font-family: "Gotham Rounded", sans-serif;
  user-select: none;
  --s: 110vh;
  --easing: cubic-bezier(0.23, 1, 0.32, 1);
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  overflow: hidden;
  height: 100vh;
  background: #8ae0fc;
  background: radial-gradient(ellipse at center, #8ae0fc 0%, #045fea 100%);
}
#root {
  overflow: hidden;
}
a {
  text-decoration: none;
}
.ScrollbarsCustom {
  flex: 1 !important;
  width: 100% !important;
  height: 100% !important;
  min-height: 0 !important;
}
.ScrollbarsCustom-Scroller {
  padding-right: calc(var(--s) / (100 / 4)) !important;
}
.ScrollbarsCustom-Track {
  width: calc(var(--s) / (100 / 1.4)) !important;
  padding: calc(var(--s) / (100 / 0.34)) !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  border-radius: 9999px !important;
}
.ScrollbarsCustom-Thumb {
  background-color: #ff9a55 !important;
  border-radius: 9999px !important;
}

