@import "../assets/fonts/stylesheet.css";


@function gh($v) {
    // @return percentage($number: $v / 1080);
    @return #{(($v / 979) * 100)}vh;
}

@function gw($v) {
    @return #{(($v / 1920) * 100)}vw;
}

html, body {
    font-family: 'Gotham Rounded';
}

button, input, textarea, select {
    outline: none;
}

.fwfh {
    width: 100%;
    height: 100%;
}

.main-container {
    width: 100vw;
    height: 100vh;
    padding: 3.33vh;
    .main-left {
        position: absolute;
        left: 3.33vh;
        top: gh(120);
        .job-name {
            color: white;
            font-family: 'Gotham Rounded Book';
            font-size: gh(22);
            text-transform: uppercase;
        }
        .user-name {
            color: white;
            font-weight: 700;
            font-size: gh(25);
            text-transform: uppercase;
        }
        .avatar-cont {
            width: gw(153);
            height: gh(140);
            padding: gh(1);
            background-color: #fff;
            box-sizing: border-box;
            margin-bottom: gh(10);
            border-radius: gh(20);
            overflow: hidden;
            -webkit-box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36);
            -moz-box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36);
            box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36); 
            .avatar-cont-in {
                width: 100%;
                height: 100%;
                border-radius: gh(20);
                border: gh(2) solid #EC5A2D;
                background: rgb(145,201,234);
                background: -moz-radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
                background: -webkit-radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
                background: radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#91c9ea",endColorstr="#1e9cd7",GradientType=1);
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                overflow: hidden !important;
                img {
                    top: gh(5);
                    width: 100%;
                    height: auto;
                    position: absolute;
                }
            }
        }
        .live-bar-cont, .motivation-bar-cont, .point-bar-cont {
            width: gw(217.774);
            height: gh(47.034);
            margin-bottom: gh(20);
            position: relative;
            display: flex;
            -webkit-box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36);
            -moz-box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36);
            box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36); 
            .question-mark {
                width: gh(21.924);
                height: gh(21.924);
                position: absolute;
                right: gh(-6);
                top: gh(-6);
                transition: all .2s;
                cursor: pointer;
                &:hover {
                    transform: scale(1.05);
                }
            }
            .green-add {
                width: gh(23.642);
                height: gh(23.642);
                position: absolute;
                bottom: gh(-6);
                right: gh(-6);
            }
            .bar-cont {
                width: gw(141.678) !important;
                height: gh(33.938) !important;
                background-image: url('../assets/bar_text_bg.svg');
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                margin-right: gw(5);
                margin-bottom: gh(5);
                position: relative;
                overflow: hidden;
                span {
                    height: gh(28.938);
                    background-color: #F44A2D;
                    position: absolute;
                    left: gw(2);
                    border-radius: gh(16);
                    top: gh(2)
                }
                p {
                    position: absolute;
                    z-index: 999;
                    left: gw(10);
                    bottom: 0;
                    margin: 0;
                    padding: 0;
                    font-weight: 700;
                    color: white;
                    font-size: gh(18);
                    line-height: gh(28.938);
                    height: gh(28.938);
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: gw(108.131);
                    height: gh(3.512);
                    background-image: url('../assets/bar_white_shadow.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: left center;
                    top: gh(5);
                    left: gw(10);

                }
            }
        }
        .live-bar-cont {
            margin-top: gh(30);
            background-image: url('../assets/live_bar.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: left center;
            align-items: flex-end;
            justify-content: flex-end;
        }
        .motivation-bar-cont {
            width: gw(217.774) !important;
            height: gh(49.54) !important;
            background-image: url('../assets/motivation_bar.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: left center;
            align-items: flex-end;
            justify-content: flex-end;
            .bar-cont {
                span {
                    background-color: #50E941 !important;
                }
            }
        }
        .point-bar-cont {
            width: gw(214) !important;
            background-color: #fff;
            border-radius: gh(24.5);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-left: gw(15);
            padding-right: gw(15);
            .point-kumbara {
                height: 80%;
                width: auto !important;
            }
            div {
                height: 100%;
                span {
                    margin-right: gw(10);
                    color: #f79041;
                    font-weight: 500;
                    font-size: gh(35);
                }
            }
            .point-p-icon {
                height: 60% !important;
                width: auto !important;
            }
        }
        .other-button {
            width: auto;
            height: gh(67);
            margin-bottom: gh(20);
            cursor: pointer;
            transition: all .2s;
            &:hover {
                transform: scale(1.05);
            }
        }
    }
}

.header-container {
    width: 100%;
    height: gh(60);
    div {
        height: 100%;
    }
    img {
        height: 100%;
        width: auto;
        transition: all .2s;
        cursor: pointer;
        &:hover {
            transform: scale(1.05);
        }
    }
}

.right-container {
    width: gw(1500);
    height: 100vh;
    right: 10.33vh;
    top: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.full {
        width: 100vw - 9vw !important;
    }
}

.scrollview-cont {
    width: gw(550);
    height: gh(600);
    background-color: #fff;
    border: gh(8) solid #F44A2D;
    border-radius: gh(72);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .scrollview-title {
        width: 100%;
        font-size: gh(55);
        font-weight: 700;
        text-align: center;
        color: #f79041;
    }
    .scrollview {
        width: gw(510);
        height: gh(460);
        background: url('../assets/scrollview_bg.svg') center center no-repeat;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: gw(40);
        .scrollview-in {
            border-radius: gh(40);
            width: 100%;
            height: 100%;
            .custom-scrollbar {
                width: gw(20) !important;
                position: absolute;
                right: 0;
                height: 95%;
                top: 2.5%;
                border-radius: gh(10);
                background-color: #ffe1c9;
                padding-bottom: gh(10);
            }
            .custom-scrollbar-thumb {
                width: gw(10) !important;
                border-radius: gw(10);
                margin-left: gw(5);
                margin-top: gh(5);
                background-color: #ff9a55;
            }
            .scroll-item {
                width: calc(96% - 2vw);
                margin-left: gw(20);
                margin-top: gh(10);
                padding: gh(25);
                background-color: #fff;
                border-radius: gh(40);
                margin-bottom: gh(20);
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                position: relative;
                transition: all .2s;
                &:hover {
                    transform: scale(1.02);
                }
                &:last-child {
                    margin-bottom: 0 !important;
                }
                .image-cont {
                    width: gh(100);
                    height: gh(100);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: auto;
                        max-width: 100%;
                        height: auto;
                        max-height: 100%;
                    }
                }
                .content-cont {
                    width: calc(70%);
                    padding-left: gh(15);
                    .item-title {
                        font-size: gh(24);
                        font-weight: 700;
                        color: #f79041;
                        margin-bottom: gh(30);
                    }
                    div {
                        span {
                            font-size: gh(18);
                            color: #f79041;
                            &:after {
                                content: ' /';
                                margin-right: gw(10);
                            }
                        }
                        .motivation, .amounts.motivation, .health {
                            font-size: gh(18);
                            color: #f79041;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &:after {
                                content: '';
                                width: gh(18);
                                height: gh(18);
                                display: inline-block;
                                margin-left: gw(5);
                                margin-right: gw(20);
                                background: url('../assets/motivation.svg') center center no-repeat;
                            }
                        }
                        .point {
                            font-size: gh(18);
                            color: #f79041;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &:after {
                                content: '';
                                width: gh(18);
                                height: gh(18);
                                display: inline-block;
                                margin-left: gw(5);
                                margin-right: gw(20);
                                background: url('../assets/p_icon.svg') center center no-repeat;
                            }
                        }
                    }
                }
                &.selected {
                    transform: scale(1.02) !important;
                    &:after {
                        content: '';
                        width: gh(49);
                        height: gh(49);
                        position: absolute;
                        top: gh(10);
                        background: url('../assets/check_icon.svg') center center no-repeat;
                        background-size: cover;
                        right: gh(10);
                    }
                }
            }
        }
        .scrollview-scroll {
            // margin-left: gw(20);
            width: gw(20);
            height: 100%;
            background-color: #ffe1c9;
            border-radius: gh(10);
        }
    }
}

.mobile-helper {
    display: none;
}

.blurred-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
}


@media (max-width: 920px) and (orientation: portrait) {
    .mobile-helper {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background: rgb(145,201,234);
        background: -moz-radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
        background: -webkit-radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
        background: radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#91c9ea",endColorstr="#1e9cd7",GradientType=1);
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        font-weight: 700;
        text-align: center;
        box-sizing: border-box;
        padding: 1rem;
        color: #fff;
        text-shadow: 4px 2px 0 #035fea,
                    -1px -1px 0 #035fea,
                    1px -1px 0 #035fea,
                    -1px 1px 0 #035fea,
                    1px 1px 0 #035fea;
    }
}

.profil-tamam {
    width: gw(699);
    height: gh(604);
    background-color: #fff;
    border: gh(8) solid #F44A2D;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: gw(30);
    color: #f79041;
    font-family: 'Gotham Rounded Book';
    font-size: gh(32);
    text-align: center;
    border-radius: gh(70);
    flex-direction: column;
    strong {
        font-weight: 700;
        font-family: 'Gotham Rounded';
        text-transform: uppercase;
    }
}

.scale {
    transition: transform .1s;
    cursor: pointer;
    &:hover {
        transform: scale(1.05) !important;
    }
}

.profile-container-in {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.account-container {
    background-color: #fff;
    border: gh(8) solid #F44A2D;
    border-radius: gh(70);
    position: relative;
    padding: gw(30);
}

.profile-container {
    width: 95%;
    height: 90%;
    background-color: #fff;
    border: gh(8) solid #F44A2D;
    border-radius: gh(70);
    position: relative;
    padding: gw(30);
    padding-left: gw(120);
    .profile-in {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        .profile-in-half {
            width: 48%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .profile-image-cont {
                width: 100%;
                height: gh(312);
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                img {
                    width: auto;
                    height: 100%;
                }
                div {
                    display: flex;
                    flex-direction: column;
                    margin-top: gh(20);
                    .job {
                        font-size: gh(36);
                        font-family: 'Gotham Rounded Book';
                        text-transform: uppercase;
                        color: #f79041;
                    }
                    .name {
                        font-size: gh(36);
                        font-weight: 700;
                        text-transform: uppercase;
                        color: #f79041;
                    }
                }
            }
            .job-desc {
                font-size: gh(26);
                color: #f79041;
                margin-top: gh(40);
            }
            .iban {
                font-size: gh(29);
                color: #f79041;
                margin-top: gh(40);
                margin-bottom: gh(40);
            }      
            .block-title {
                width: 100%;
                font-size: gh(29);
                color: #f79041;
                font-weight: 700;
                padding-bottom: gh(10);
                margin-bottom: gh(20);
                border-bottom: gh(5) solid #f79041;
                margin-top: gh(20);
                &.green {
                    color: #50E941;
                    border-color: #50E941;
                }
            }      
            .block-item {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
                font-size: gh(29);
                color: #f79041;
                font-family: 'Gotham Rounded Book';
                margin-bottom: gh(10);
                span:last-child {
                    display: flex;
                    align-items: center;
                    &:after {
                        content: '';
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: gw(5);
                        background: url('../assets/p_icon.svg') center center no-repeat;
                        background-size: 80%;
                        width: gh(29);
                        height: gh(29);
                    }
                }
            }
        }
    }
    .badge-container {
        width: gw(180);
        height: 90%;
        background: rgb(241,90,41);
        background: -moz-linear-gradient(0deg, rgba(241,90,41,1) 0%, rgba(246,191,47,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(241,90,41,1) 0%, rgba(246,191,47,1) 100%);
        background: linear-gradient(0deg, rgba(241,90,41,1) 0%, rgba(246,191,47,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f15a29",endColorstr="#f6bf2f",GradientType=1);
        position: absolute;
        top: 5%;
        left: gh(-90);
        border-radius: gh(180);
        padding: gw(20);
        .badge-container-in {
            width: 100%;
            height: 100%;
            background: rgb(242,110,42);
            background: -moz-linear-gradient(0deg, rgba(242,110,42,1) 0%, rgba(248,214,49,1) 100%);
            background: -webkit-linear-gradient(0deg, rgba(242,110,42,1) 0%, rgba(248,214,49,1) 100%);
            background: linear-gradient(0deg, rgba(242,110,42,1) 0%, rgba(248,214,49,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f26e2a",endColorstr="#f8d631",GradientType=1);
            border-radius: gh(140);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            img {
                width: 60%;
                height: auto;
                cursor: pointer;
                transition: all .2s;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.account-container {
    width: gw(1085);
    padding: gw(60);
    .avatar-cont {
        width: gw(153);
        height: gh(140);
        padding: gh(1);
        background-color: #fff;
        box-sizing: border-box;
        margin-bottom: gh(10);
        border-radius: gh(20);
        overflow: hidden;
        .avatar-cont-in {
            width: 100%;
            height: 100%;
            border-radius: gh(20);
            border: gh(2) solid #EC5A2D;
            background: rgb(145,201,234);
            background: -moz-radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
            background: -webkit-radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
            background: radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#91c9ea",endColorstr="#1e9cd7",GradientType=1);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden !important;
            img {
                top: gh(5);
                width: 100%;
                height: auto;
                position: absolute;
            }
        }
    }
    .job-name {
        font-size: gh(29);
        color: #f79041;
        text-transform: uppercase;
        width: 100%;
        display: inline-block;
    }
    .user-name {
        font-size: gh(38);
        color: #f79041;
        font-weight: 700;
        text-transform: uppercase;
        width: 100%;
    }    
    .title-img {
        height: gh(120);
        width: auto;
        position: absolute;
        right: gw(60);
    }
}

.profile-form {
    width: 100%;
    display: table;
    .profile-input {
        width: 100%;
        border: gh(3) solid #f79041;
        text-align: center;
        font-family: 'Gotham Rounded Book';
        height: gh(49);
        border-radius: gh(7);
        font-size: gh(19);
        &.error {
            border-color: red;
            &::placeholder {
                color: red;
            }
        }
    }
    label {
        margin-bottom: gh(10);
        display: inline-block;
        font-size: gh(24);
        font-weight: 700;
        color: #f79041;
        &:first-child {
            margin-top: gh(50);
        }
    }
    .profile-form-left {
        width: 49%;
        display: table-cell;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .profile-form-right {
        width: 49%;
        height: 100%;
        display: table-cell;
        position: relative;
        img {
            width: auto;
            height: gh(78);
            position: absolute;
            bottom: gh(20);
            right: 0;
        }
    }
}

.warning-cont {
    width: gw(1016);
    height: gh(620);
    border-radius: gh(80);
    background-color: #fff;
    border: gh(8) solid #f79041;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: gw(40);
    padding-right: gw(40);
    .title-img {
        width: auto;
        height: gh(112);
        position: absolute;
        top: gh(-56);
    }
    .link-img {
        width: auto;
        height: gh(112);
        position: absolute;
        bottom: gh(-56);        
    }
    span {
        text-align: center;
        font-size: gh(36);
        font-family: 'Gotham Rounded Book';
        color: #f79041;
        strong {
            font-family: 'Gotham Rounded';
            font-weight: 700;
        }
    }
}

.c-scroll-main {
    background-color: #ffd551;
    border-radius: gh(60);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    -webkit-box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36);
    -moz-box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36);
    box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36); 
    .c-scroll-in {
        width: calc(100% - 2vw);
        height: calc(100% - 2vw);
        background-color: #f79041;
        position: absolute;
        z-index: 5;
        border-radius: gh(60);
        box-sizing: border-box;
        padding: gh(20);
        .c-scroll-content {
            width: 100%;
            height: 100%;
            .custom-scrollbar {
                width: gw(20) !important;
                position: absolute;
                right: 0;
                height: 95%;
                top: 2.5%;
                border-radius: gh(10);
                background-color: #ffe1c9;
                padding-bottom: gh(10);
            }
            .custom-scrollbar-thumb {
                width: gw(10) !important;
                border-radius: gw(10);
                margin-left: gw(5);
                margin-top: gh(5);
                background-color: #ff9a55;
            }            
        }
    }
    .c-scroll-top {
        width: calc(100% - 2vw);
        height: calc(100% - 2vw);
        background-color: #e28a47;
        position: absolute;
        top: gh(10);
        z-index: 4;
        border-radius: gh(60);        
    }
    .c-scroll-bottom {
        width: calc(100% - 2vw);
        height: calc(100% - 2vw);
        background-color: #ffe1c9;
        position: absolute;
        bottom: gh(10);
        z-index: 3;
        border-radius: gh(60);        
    }    
}

.esyalarim-list {
    width: 98%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    padding: gh(10);
    .esyalarim-list-item {
        width: 30%;
        padding: gh(10) gh(40);
        border: gh(4) solid #EC5A2D;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: gh(30);
        border-radius: gh(140);
        margin-right: gw(40);
        -webkit-box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36);
        -moz-box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36);
        box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36); 
        cursor: pointer;
        &:nth-child(3n + 0) {
            margin-right: 0;
        }
        .image-cont {
            width: gh(108);
            height: gh(108);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: gw(20);
            img {
                width: auto;
                height: auto;
                max-height: 90%;
                max-width: 90%;
            }
        }
        .content-cont {
            display: flex;
            flex-direction: column;
            span {
                color: #e28a47;
                font-size: gh(32);
                font-weight: 700;
                &:last-child {
                    font-size: gh(18);
                    font-family: 'Gotham Rounded Book';
                    font-weight: 400;
                    margin-top: gh(5);
                }
            }
            .coin, .motivation, .health, .point {
                font-size: gh(38) !important;
                font-weight: 700;
                margin-top: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;
                margin-right: gw(30);
                &:after {
                    content: '';
                    width: gh(34);
                    height: gh(34);
                    margin-left: gw(5);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-image: url('../assets/p_icon.svg');
                }                        
            }
            .motivation:after {
                background-image: url('../assets/motivation.svg');
            }
            .health:after {
                background-image: url('../assets/live.svg');
            }  
            .point:after {
                background-image: url('../assets/p_icon.svg');
            }           
        }
    }
}

.one-list {
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    .one-list-item {
        width: 80%;
        padding: gh(10) gh(40);
        background-color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: gh(30);
        border-radius: gh(30);
        .image-cont {
            width: gh(108);
            height: gh(108);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: gw(20);
            img {
                width: auto;
                height: auto;
                max-height: 90%;
                max-width: 90%;
            }
        }
        .content-cont {
            display: flex;
            flex-direction: column;
            span {
                color: #e28a47;
                font-size: gh(32);
                font-weight: 700;
                &:last-child {
                    font-size: gh(18);
                    font-family: 'Gotham Rounded Book';
                    font-weight: 400;
                    margin-top: gh(5);
                }
            }
        }
    }
    .one-list-item.food {
        width: 90%;
        padding: 0 0;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: gh(30);
        border-radius: 0;
        cursor: pointer;
        transition: all .2s;
        &:hover {
            transform: scale(1.05);
        }
        .image-cont {
            width: gh(90);
            height: gh(90);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: gw(20);
            img {
                width: auto;
                height: auto;
                max-height: 90%;
                max-width: 90%;
            }
        }
        .content-cont {
            display: flex;
            flex-direction: column;
            span {
                color: #fff;
                font-size: gh(28);
                font-weight: 700;
                margin-bottom: gh(5);
            }
            .health, .point {
                font-size: gh(26) !important;
                font-weight: 700;
                margin-top: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;
                margin-right: gw(20);
                &:after {
                    content: '';
                    width: gh(24);
                    height: gh(24);
                    margin-left: gw(5);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-image: url('../assets/p_icon.svg');
                }
            }
            .health {
                &:after {
                    background-image: url('../assets/live.png');
                }
            }
        }
    }    
}

.esyalarim-total {
    width: gw(354.398);
    height: gh(96.995);
    position: absolute;
    bottom: gh(-45);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #fff;
        font-size: gh(24);
        font-weight: 400;
        font-family: 'Gotham Rounded Book';
        strong {
            font-family: 'Gotham Rounded';
            font-weight: 700;
        }
    }
}

.olaylar-cont {
    width: 100%;
    height: gh(783);
    background-color: #fff;
    border: gh(8) solid #F44A2D;
    border-radius: gh(80);
    overflow: hidden;
    .olaylar-thead {
        position: relative;
        width: 100%;
        padding: gh(30);
        border-bottom: gh(8) solid #F44A2D;
        img.close {
            width: gh(60);
            height: gh(60);
            position: absolute;
            top: gh(30);
            right: gh(30);
        }
        .img-olaylar {
            height: gh(129);
            width: auto;
        }
        .thead-column {
            width: gw(225);
            img {
                height: gh(53);
                width: auto;
            }
        }
    }
    .olaylar-body {
        width: 100%;
        height: 74%;
        background-color: rgba(247,144,65,0.1);
        padding: gh(30);
        .olaylar-row {
            width: 100%;
            margin-bottom: gh(20);
            .olaylar-text-column {
                width: calc(100% - 225px);
                span {
                    color: #f79041;
                    font-size: gh(29);
                    font-family: 'Gotham Rounded Book';
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: flex-start;
                    &:before {
                        content: '';
                        width: gh(10);
                        height: gh(10);
                        background-color: #f79041;
                        border-radius: 100%;
                        margin-right: gw(10);
                        margin-top: gh(3);
                    }
                }
            }
            .olaylar-motivation-column, .olaylar-point-column {
                display: flex;
                align-items: center;
                justify-content: center;
                width: gw(225);
                margin-right: gh(30);
                span {
                    color: #62cc1d;
                    font-weight: 700;
                    font-size: gh(29);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    &:before {
                        content: '';
                        width: gh(29);
                        height: gh(29);
                        margin-right: gw(10);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-image: url('../assets/motivation.svg');
                    }
                }
            }
            .olaylar-point-column {
                span {
                    &:before {
                        background-image: url('../assets/p_icon.svg');
                    }
                }
            }
        }
    }
}

.sorumluluk-cont {
    width: gw(1250);
    height: gh(783);
    padding-left: gw(30);
    padding-right: gw(30);
    background-color: #fff;
    border: gh(8) solid #F44A2D;
    border-radius: gh(80);
    overflow: hidden;
    position: relative;
    .sorumluluk-thead {
        width: 100%;
        padding: gh(30);
        img.close {
            width: gh(60);
            height: gh(60);
            position: absolute;
            top: gh(30);
            right: gh(30);
        }
        .img-olaylar {
            height: auto;
            width: 80%;
        }
        .thead-column {
            width: gw(225);
            img {
                height: gh(53);
                width: auto;
            }
        }
    }
    .sorumluluk-body {
        width: 100%;
        height: 74%;
        padding-left: gh(30);
        padding-right: gh(30);
        padding-bottom: gh(30);
        .sorumluluk-row {
            padding-left: gh(20);
            padding-top: gh(20);
            .katil-button {
                height: gh(78.731);
                width: auto;
            }
            .text {
                font-family: 'Gotham Rounded Book';
                font-weight: 400;
                color: #e28a47;
                font-size: gh(26);
                margin-bottom: gh(5);
            }
            .motivation, .point {
                color: #62cc1d;
                font-weight: 700;
                font-size: gh(22);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-right: gw(20);
                &:before {
                    content: '';
                    width: gh(29);
                    height: gh(29);
                    margin-right: gw(10);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-image: url('../assets/motivation.svg');
                }
            }
            .point {
                &:before {
                    background-image: url('../assets/p_icon.svg');
                }
            }
        }
    }
}

.custom-scrollbar {
    width: gw(20) !important;
    position: absolute;
    right: 0;
    height: 95%;
    top: 2.5%;
    border-radius: gh(10);
    background-color: #ffe1c9;
    padding-bottom: gh(10);
}
.custom-scrollbar-thumb {
    width: gw(10) !important;
    border-radius: gw(10);
    margin-left: gw(5);
    margin-top: gh(5);
    background-color: #ff9a55;
}

.in-page-popup {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    .in-page-popup-cont {
        width: gw(602);
        height: 90%;
        background-color: #fff;
        border: gh(8) solid #e28a47;
        border-radius: gh(80);
        position: absolute;
        z-index: 99999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .close {
            width: gh(48);
            height: gh(48);
            position: absolute;
            top: gh(15);
            right: gh(30);
        }
        .title {
            width: 100%;
            font-size: gh(38);
            font-weight: 700;
            text-align: center;
            color: #f79041;
        }
        .one-item {
            padding: 0 0;
            background-color: transparent;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: gh(30);
            border-radius: 0;
            .image-cont {
                width: gh(120);
                height: gh(120);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: gw(20);
                img {
                    width: auto;
                    height: auto;
                    max-height: 90%;
                    max-width: 90%;
                }
            }
            .content-cont {
                display: flex;
                flex-direction: column;
                span {
                    color: #fff;
                    font-size: gh(42);
                    font-weight: 700;
                    margin-bottom: gh(5);
                }
                .health, .point, .motivation {
                    font-size: gh(38) !important;
                    font-weight: 700;
                    margin-top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: row;
                    margin-right: gw(30);
                    &:after {
                        content: '';
                        width: gh(34);
                        height: gh(34);
                        margin-left: gw(5);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-image: url('../assets/p_icon.svg');
                    }
                }
                .health:after {
                    background-image: url('../assets/live.png');
                }
                .motivation:after {
                    background-image: url('../assets/motivation_white.svg');
                }
            }
        }         
    }
    .close-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 99998;
    }
}

.c-scroll-content {
    .search {
        width: 100%;
        height: gh(48);
        border-radius: gh(24);
        background-color: #FDDDC6;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
            border: 0;
            background-color: transparent;
            width: 100%;
            height: 100%;
            font-weight: 700;
            font-family: 'Gotham Rounded';
            box-sizing: border-box;
            padding-left: gw(30);
            padding-right: gw(40);
            color: #e28a47;
            &::placeholder {
                color: #e28a47;
            }
        }
        img {
            position: absolute;
            width: gh(30);
            height: gh(30);
            right: gw(10);
        }
    }
}

.friend-list-item {
    width: 90%;
    padding-left: gw(60);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: gh(10);
    margin-bottom: gh(10);
    position: relative;
    .avatar-cont {
        width: gh(48);
        height: gh(48);
        background-color: #ccc;
        border: gh(1) solid #fff;
        border-radius: 100%;
        margin-right: gw(20);
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(145,201,234);
        background: -moz-radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
        background: -webkit-radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
        background: radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#91c9ea",endColorstr="#1e9cd7",GradientType=1);
        img {
            width: auto;
            height: 100%;
        }
    }
    span {
        font-size: gh(32);
        font-weight: 700;
        color: white;
        text-shadow: 2px 1px 0 #EC5A2D,
        -1px -1px 0 #EC5A2D,
        1px -1px 0 #EC5A2D,
        -1px 1px 0 #EC5A2D,
        1px 1px 0 #EC5A2D;
    }
}

.friend-list-item.selected:before {
    content: '';
    width: gh(36);
    height: gh(36);
    position: absolute;
    left: gw(15);
    background-position: center;
    background-image: url('../assets/check_icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.friends-cont {
    padding: gh(20);
    background-color: #fff;
    border-radius: gh(80);
    background: rgb(241,90,41);
    background: -moz-linear-gradient(0deg, rgba(241,90,41,1) 0%, rgba(246,191,47,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(241,90,41,1) 0%, rgba(246,191,47,1) 100%);
    background: linear-gradient(0deg, rgba(241,90,41,1) 0%, rgba(246,191,47,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f15a29",endColorstr="#f6bf2f",GradientType=1);
}

.friend-list-item-2 {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: gh(10);
    margin-bottom: gh(20);
    position: relative;
    .avatar-cont {
        width: gh(56);
        height: gh(56);
        border: gh(1) solid #fff;
        border-radius: 100%;
        margin-right: gw(20);
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(145,201,234);
        background: -moz-radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
        background: -webkit-radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
        background: radial-gradient(circle, rgba(145,201,234,1) 0%, rgba(30,156,215,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#91c9ea",endColorstr="#1e9cd7",GradientType=1);
        img {
            width: auto;
            height: 100%;
        }
    }
    span {
        font-size: gh(34);
        font-weight: 700;
        color: white;
        text-shadow: 2px 1px 0 #EC5A2D,
        -1px -1px 0 #EC5A2D,
        1px -1px 0 #EC5A2D,
        -1px 1px 0 #EC5A2D,
        1px 1px 0 #EC5A2D;
    }
    img.button {
        height: gh(48);
        width: auto;
    }
}


.calis-bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    &.bg-1 {
        background-image: url('../assets/avukat_bg.png');
        background-position: center 10vh;
        background-size: contain;
        background-repeat: no-repeat;
    }
    &.bg-2 {
        background-image: url('../assets/sanatci_bg.png');
        background-position: bottom center;
        background-size: 110%;
        background-repeat: no-repeat;
    }
    &.bg-3 {
        background-image: url('../assets/doktor_bg.png');
        background-position: center 5vh;
        background-size: 109%;
        background-repeat: no-repeat;
    }
    &.bg-4 {
        background-image: url('../assets/muhendis_bg.png');
        background-position: bottom right;
        background-size: contain;
        background-repeat: no-repeat;
    }            
}

.avatar-full-cont {
    width: gw(426);
    height: gh(629);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    left: gh(-180);
    bottom: 0;
    img {
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
}

.question-mark {
    position: absolute;
    right: 0;
    top: -5%;
    width: 10%;
    height: auto;
}

.calis-main-cont {
    width: 85%;
    padding: gh(60);
    background-color: rgba(255,255,255,0.85);
    border-radius: gh(80);
    margin-top: gh(200);
    .gradient-cont {
        width: 49%;
        background: rgb(245,166,46);
        background: -moz-linear-gradient(90deg, rgba(245,166,46,1) 0%, rgba(241,90,41,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(245,166,46,1) 0%, rgba(241,90,41,1) 100%);
        background: linear-gradient(90deg, rgba(245,166,46,1) 0%, rgba(241,90,41,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5a62e",endColorstr="#f15a29",GradientType=1);
        border-radius: gh(80);
        padding: gh(18);
        position: relative;
        .gradient-cont-in {
            width: 100%;
            background: rgb(247,202,48);
            background: -moz-linear-gradient(90deg, rgba(247,202,48,1) 0%, rgba(242,115,43,1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(247,202,48,1) 0%, rgba(242,115,43,1) 100%);
            background: linear-gradient(90deg, rgba(247,202,48,1) 0%, rgba(242,115,43,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f7ca30",endColorstr="#f2732b",GradientType=1);    
            border-radius: gh(80);    
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            padding: gh(20) gw(40);
            span.gradient-left {
                font-size: gh(29);
                font-weight: 700;
                color: #fff;
            } 
            span.gradient-right {
                font-size: gh(84);
                font-weight: 700;
                color: #fff;
                display: flex;
                flex-direction: row;
                align-items: center;
                &:after {
                    content: '';
                    width: gh(64);
                    height: gh(64);
                    margin-left: gw(10);
                    background-image: url('../assets/p_icon.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }    
        }        
    }
    .calis-slider-cont {
        width: gw(690);
        margin-right: gw(60);
        .spans {
            position: relative;
            span {
                font-size: gh(32);
                font-weight: 700;
                bottom: gh(10);
                position: absolute;
                color: #e28a47;
                display: flex;
                align-items: center;
                flex-direction: row;
                &:after {
                    content: '';
                    width: gh(28);
                    height: gh(28);
                    margin-left: gw(10);
                    background-image: url('../assets/p_icon.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
            span:first-child {
                left: 0;
            }
            span:nth-child(2) {
                left: 21%;
            }
            span:last-child {
                right: 0;
            }
        }
        .calis-slider-in {
            width: 100%;
            height: gh(48);
            border-radius: gh(24);
            border: gh(6.5) solid #e28a47;
            .slider {
                height: 100%;
                background: rgb(51,255,80);
                background: -moz-linear-gradient(90deg, rgba(51,255,80,1) 0%, rgba(96,222,57,1) 100%);
                background: -webkit-linear-gradient(90deg, rgba(51,255,80,1) 0%, rgba(96,222,57,1) 100%);
                background: linear-gradient(90deg, rgba(51,255,80,1) 0%, rgba(96,222,57,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#33ff50",endColorstr="#60de39",GradientType=1); 
                border-radius: gh(24);               
            }
        }
    }
    .calis-button-cont {
        position: relative;
        .calis-button {
            width: gw(155);
            height: gh(102);
            background-image: url('../assets/calis_button.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            position: relative;
            cursor: pointer;
            transition: all .1s;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                transform: scale(1.1);
            }
            &.working {
                background-image: none !important;
                background-color: #62cc1d;
                border-radius: gh(20);
                transform: none !important;
                img {
                    height: 70%;
                }
            }
        }
        .question-mark {
            width: 20% !important;
            right: -25%;
        }
    }
}

.calis-olay-cont {
    width: 85%;
    padding: gh(45);
    position: absolute;
    background: rgba(255,255,255,0.85);
    top: gh(60);
    border-radius: gh(120);
    left: 7.5%;
    .close {
        width: gh(45);
        height: auto;
        position: absolute;
        right: gh(60);
        top: gh(15);
    }
    .olay-text {
        font-weight: 700;
        font-size: gh(29);
        padding-left: gh(45);
        padding-right: gh(45);
        margin-bottom: gh(30);
        text-align: center;
    }
    .coin, .motivation {
        padding: gh(15) gh(30);
        background-color: #fff;
        border-radius: gh(35);
        font-size: gh(38);
        color: #ff9a55;
        font-weight: 700;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: gw(30);
        &:after {
            content: '';
            width: gh(36);
            height: gh(36);
            margin-left: gw(10);
            background-image: url('../assets/p_icon.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }
    .motivation {
        margin-right: 0;
        &:after {
            background-image: url('../assets/motivation.svg');
        }
    }
}

.shadow {
    -webkit-box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36);
    -moz-box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36);
    box-shadow: 9px 9px 25px -5px rgba(0,0,0,0.36); 
}

.profil-input-error {
    font-size: gh(11);
    color: red;
    padding: 0;
    margin: 0;
    margin-top: gh(5);
    margin-bottom: gh(25);
}

.box-wrapper {
    width: gw(1121);
    height: gh(661);
    margin-bottom: gh(30);
    background-image: url('../assets/welcome-bg.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    img {
        width: 65%;
        height: auto;
    }
}